








































































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
extend('required', {
  ...required,
  message: 'Field is required',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ResetPassword extends mixins(WidgetMixins) {
  bg: string = require('@/assets/images/dashboard-1.png');
  show: boolean = false;
  option: string | null = null;
  error: string | null = null;

  chosenOption(key: string): void {
    this.error = null;
    this.option = key;
  }

  nextPage(): void {
    if (!this.option) {
      this.error = `Choose an option`;
      return;
    }
    this.error = null;
    if (this.option === 'personal') {
      this.$router.push({
        name: 'accounts.signup.developer',
      });
    } else {
      this.$router.push({
        name: 'accounts.signup.business',
      });
    }
  }
}
